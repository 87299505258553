import { writable, derived } from 'svelte/store';

export const contracts = writable(false);
export const selectedContract = writable(null);
export const selectedContractId = writable(null);
export const allObis = derived(selectedContract, (contract) => {
  if (contract == null) return [];
  return contract.attributes.device.registers.map(r => r.obis);
});
export const selectedReadingsGroupedByObis = derived([selectedContract, allObis], ([contract, allObis]) => {
  if (contract == null) return null;
  return allObis.map(obis => {
    let obisReadings = [];
    for (const reading of contract.attributes.device.readings) {
      if (reading.readingValues[0].obis === obis) {
        obisReadings.push(reading);
      }
    }
    return obisReadings;
  });
});
export const editReading = writable({
  reading_date: new Date().toLocaleDateString('en-CA'),
  reading_meter: '',
  reading_type: null,
});
export const forceImplausibleSaving = writable(false);
export const orders = writable(false);
export const showCancelledContractModal = writable(true);

export const customer = writable(false);
