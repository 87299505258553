import { writable, get } from 'svelte/store';
import { passwordResetState } from '../stores/passwordResetState';
import { redirectPath } from '../stores/redirectPath';
import { showCancelledContractModal } from '../stores/contracts';
let { region } = document.body.dataset;
region = region === 'bemo' ? 'schwandorf' : region;

function setProperties(props) {
  MDType.set(props.type);
  MDTopic.set(props.topic);
  MDHeadline.set(props.headline);
  MDIcon.set(props.icon);
  MDMessage.set(props.message);
  MDActions.set(props.actions);
}

export function MDShow() {
  // console.debug('MDShow');
  MDisVisible.set(true);
}

export function MDHide(reloadAfterHide = false) {
  console.debug(`MDHide. reloadAfterHide:${reloadAfterHide}`);
  console.debug('MDHide', get(passwordResetState));
  MDisVisible.set(false);

  // Using an alternative to haystack.startsWith(needle)
  // we look it the state starts with 'PASSWORD' to
  // catch symbols like 'PASSWORD_SET_NEW', 'PASSWORD_RESET' and others.
  if (get(passwordResetState).lastIndexOf('PASSWORD', 0) === 0) {
    passwordResetState.set('SIGNED_OUT');
    window.location.search = '';
  }

  if (reloadAfterHide === true) {
    window.console.debug('reloadAfterHide');
    window.location.reload();
  }
}

// presets
let MDPresets = {
  defaultPresets: {
    type: 'confirmation',
    topic: 'Zugang verweigert',
    headline: 'Bitte melden Sie sich an.',
    icon: null,
    message:
      '<p>Für diesen Bereich müssen Sie angemeldet sein. Falls Sie noch kein Konto bei uns haben, sollten Sie sich zunächst registrieren.</p>',
    actions: [
      {
        href: '#/login',
        label: 'Anmelden',
        cssClass: 'primary proceed',
        onClick: () => {
          MDHide();
          window.location.href = '/login';
        },
      },
      {
        href: '#/cancel',
        label: 'Abbrechen',
        cssClass: 'secondary proceed',
        onClick: () => {
          MDHide();
        },
      },
    ],
  },
  eventSuccess: {
    type: 'alert',
    topic: 'Abgespeichert',
    headline: 'Das hat geklappt.',
    message: '<p>Die Daten wurden gespeichert.</p>',
    actions: [
      {
        href: '#/events',
        label: 'Fortfahren',
        cssClass: 'secondary proceed',
        onClick: () => {
          MDHide();
        },
      },
    ],
  },
  contactSuccess: {
    type: 'alert',
    topic: 'Kontakt',
    headline: 'Danke für Ihre Mitteilung.',
    message:
      '<p>Ihre Anfrage ist bei uns eingegangen und wird bearbeitet. Wir melden uns in Kürze bei Ihnen. Herzlichen Dank!</p>',
    actions: [
      {
        href: '#/proceed',
        label: 'Fortfahren',
        cssClass: 'primary proceed',
        onClick: () => {
          MDHide();
        },
      },
    ],
  },
  unauthorized: {
    type: 'confirmation',
    topic: 'Zugang verweigert',
    headline: 'Bitte melden Sie sich an.',
    message:
      '<p>Für diesen Bereich müssen Sie angemeldet sein. Falls Sie noch kein Konto bei uns haben, sollten Sie sich zunächst registrieren.</p>',
    actions: [
      {
        href: '#/login',
        label: 'Anmelden',
        cssClass: 'primary proceed',
        onClick: () => {
          MDHide();
          window.location.href = '/login';
        },
      },
      {
        href: '#/cancel',
        label: 'Abbrechen',
        cssClass: 'secondary proceed',
        onClick: () => {
          MDHide();
        },
      },
    ],
  },
  authorizationFailed: {
    type: 'alert',
    topic: 'Anmeldung',
    headline: 'Die Anmeldung ist fehlgeschlagen.',
    message:
      '<p>Ihre Anmeldung ist leider fehlgeschlagen. Bitte überprüfen Sie die Eingaben von Benutzernamen und Passwort, und versuchen Sie es erneut.</p>',
    actions: [
      {
        href: '#/login',
        label: 'Abbrechen',
        cssClass: 'primary proceed',
        onClick: () => {
          MDHide();
        },
      },
    ],
  },
  serverError: {
    type: 'alert',
    topic: 'Server-Fehler',
    headline: 'Fehler in der Verarbeitung.',
    message:
      '<p>Leider konnte der Server Ihre Anfrage nicht korrekt verarbeiten und hat einen Fehler gemeldet. Bitte versuchen Sie es zu einem anderen Zeitpunkt erneut.</p>',
    actions: [
      {
        href: '#/cancel',
        label: 'Abbrechen',
        cssClass: 'secondary proceed',
        onClick: () => {
          MDHide();
        },
      },
    ],
  },
  login: {
    type: 'login',
    topic: 'Anmelden',
    headline: 'Bitte melden Sie sich an.',
    message:
      '<p>Melden Sie sich mit <b>Ihrer E-Mail-Adresse</b> und <b>Ihrem Passwort</b> an:</p>',
    actions: [],
  },
  readings: {
    type: 'readings',
    topic: 'Ihr Zählerstand',
    headline: 'Wie ist Ihr Zählerstand?',
    message: '',
    actions: [],
  },
  emailConfirmation: {
    type: 'emailConfirmation',
    topic: 'Bestätigung der E-Mail-Adresse',
    headline: 'Bestätigung der E-Mail-Adresse',
    message: '',
    actions: [],
  },
  unconfirmedEmail: {
    type: 'alert',
    topic: 'Zugang verweigert',
    headline: 'E-Mail-Adresse nicht bestätigt.',
    message:
      '<p>Der Zugang zum Kundenkonto wurde verweigert, weil Ihre E-Mail-Adresse noch nicht von Ihnen bestätigt wurde. Bitte bestätigen Sie Ihre E-Mail-Adresse, indem Sie den Link in der zugesandten Bestätigungs-E-Mail verwenden.</p>',
    actions: [
      {
        href: '#/cancel',
        label: 'Abbrechen',
        cssClass: 'secondary proceed',
        onClick: () => MDHide(),
      },
    ],
  },
  contractCancelled: {
    topic: 'Wichtiger Hinweis',
    headline: 'Eingeschränkter Zugriff aufs Kundenkonto.',
    message: `Guten Tag,
        <br><br>
        leider haben Sie sich dazu entschlossen Ihren Vertrag zu kündigen. Deshalb stehen Ihnen die Funktionen des Kundenportals nur noch eingeschränkt zur Verfügung.
        Weiterhin besteht die Möglichkeit Ihre persönlichen Rechnungen herunter zu laden und Ihre persönlichen Login-Daten (Passwort und E-Mail-Adresse) zu ändern.
        <br><br>
        Für weitere Frage nutzen Sie gerne unser <a href="#kontakt">Kontaktformular</a>.`,
    actions: [
      {
        label: 'Verstanden',
        cssClass: 'primary proceed',
        onClick: () => {
          showCancelledContractModal.set(false);
          MDHide();
        },
      },
    ],
  },
  openOrder: {
    topic: 'Wichtiger Hinweis',
    headline: 'Eingeschränkter Zugriff aufs Kundenportal.',
    message: `Guten Tag, <br><br> ihr Auftrag wurde vom zuständigen Netzbetreiber noch nicht bestätigt. Deshalb stehen Ihnen die Funktionen des Kundenportals nur eingeschränkt zur Verfügung. Den vollen Umfang Ihres persönlichen Kundenportals können Sie nutzen, nachdem wir die Bestätigung erhalten haben. Wir werden Sie zeitnah per E-Mail darüber informieren. <br><br> Für weitere Frage nutzen Sie gerne unser <a href="#kontakt">Kontaktformular</a>.`,
    actions: [
      {
        label: 'Verstanden',
        cssClass: 'primary proceed',
        onClick: () => MDHide(),
      },
    ],
  },
  meterReadingReminder: {
    topic: 'Wichtiger Hinweis',
    headline: 'Wir benötigen Ihren Zählerstand',
    message: `Um Ihnen eine Rechnung auf Basis Ihres tatsächlichen Verbrauchs zum 31.12.2024 erstellen zu können, benötigen wir Ihren aktuellen Zählerstand. Bitte nehmen Sie bis zum 31.12.2024 eine Ablesung Ihres Zählers vor und teilen Sie uns den Stand im Kundenportal unter dem Menüpunkt Zählerstand mit (Ausnahme intelligente Messsysteme mit SmartMeter Gateway). <br><br> Vielen Dank für Ihre Unterstützung!`,
    actions: [
      {
        label: 'Verstanden',
        cssClass: 'primary proceed',
        onClick: () => MDHide(),
      },
    ],
  },
  waitingContract: {
    topic: 'Wichtiger Hinweis',
    headline: 'Lieferung hat noch nicht begonnen.',
    message: `Guten Tag, <br><br> der Lieferbeginn für Ihren Vertrag hat noch nicht begonnen. Daher stehen Ihnen noch nicht alle Funktionen des Kundenportals zu Verfügung. <br><br> Bei weiteren Fragen nutzen Sie gerne unser <a href="#kontakt">Kontaktformular</a>.`,
    actions: [
      {
        label: 'Verstanden',
        cssClass: 'primary proceed',
        onClick: () => MDHide(),
      },
    ],
  },
  cancellationPrevious: {
    topic: 'Wichtiger Hinweis',
    headline: 'Eingeschränkter Zugriff aufs Kundenportal.',
    message: `Guten Tag, <br><br> für Ihren Auftrag warten wir auf die Kündigung des Lieferantenrahmenvertrags mit Ihrem bisherigen Energieversorger. Deshalb stehen Ihnen die Funktionen des Kundenportals nur eingeschränkt zur Verfügung. Den vollen Umfang Ihres persönlichen Kundenportals können Sie nutzen, nachdem wir die Bestätigung vom zuständigen Netzbetreiber erhalten haben. Wir werden Sie zeitnah per E-Mail darüber informieren.`,
    actions: [
      {
        label: 'Verstanden',
        cssClass: 'primary proceed',
        onClick: () => MDHide(),
      },
    ],
  }
};

export const MDisVisible = writable(false);
export const MDType = writable(MDPresets.defaultPresets.type);
export const MDTopic = writable(MDPresets.defaultPresets.topic);
export const MDHeadline = writable(MDPresets.defaultPresets.headline);
export const MDIcon = writable(MDPresets.defaultPresets.icon);
export const MDMessage = writable(MDPresets.defaultPresets.message);
export const MDActions = writable(MDPresets.defaultPresets.actions);

export function MDConfirm(settings) {
  MDType.set('confirmation');
  setProperties(settings);
  MDShow();
}

export function MDAlert(settings) {
  MDType.set('alert');
  setProperties(settings);
  MDShow();
}

export function MDShowFromPreset(preset) {
  setProperties(MDPresets[preset]);
  MDShow();
}

// shorthands

export function MDEventSuccess() {
  MDShowFromPreset('eventSuccess');
}

export function MDContactSuccess() {
  console.debug('contactSuccess');
  MDShowFromPreset('contactSuccess');
}

export function MDUnauthorized() {
  MDShowFromPreset('unauthorized');
}

export function MDAuthorizationFailed() {
  MDShowFromPreset('authorizationFailed');
}

export function MDServerError() {
  console.debug('MDServerError');
  MDShowFromPreset('serverError');
}

export function MDLogin(redirect_path = `${region}/konto/vertraege`) {
  redirectPath.set(redirect_path);
  MDShowFromPreset('login');
}

export function MDReadings() {
  MDShowFromPreset('readings');
}

export function MDEmailConfirmation() {
  MDShowFromPreset('emailConfirmation');
}

export function MDUnconfirmedEmail() {
  MDShowFromPreset('unconfirmedEmail');
}

export function MDOpenOrder() {
  MDShowFromPreset('openOrder');
}

export function MDContractCancelled() {
  MDShowFromPreset('contractCancelled');
}

export function MDMeterReadingReminder() {
  MDShowFromPreset('meterReadingReminder');
}

export function MDWaitingContract() {
  MDShowFromPreset('waitingContract');
}

export function MDCancellationPrevious() {
  MDShowFromPreset('cancellationPrevious')
};