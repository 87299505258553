import LoginWidget from './LoginWidget.svelte';
// Sentry is imported in the login widget since the widget
// is available on all pages that contain JavaScript
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

export default new LoginWidget({
  target: document.getElementById('loginwidget'),
});

Sentry.init({
  dsn: 'https://1838bd3e448f43f295766217eaa3620a@sentry.eon.com/117',
  environment: document.body.dataset.env,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});
