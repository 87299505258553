import { passwordResetState } from '../../stores/passwordResetState';
import {
  MDTopic,
  MDHeadline,
  MDIcon,
  MDMessage,
} from '../../lib/modaldialogues';

export function passwordReset() {
  passwordResetState.set('PASSWORD_RESET');
  MDTopic.set('Kein Passwort zur Hand?');
  MDHeadline.set('Passwort anfordern.');
  MDIcon.set('key');
  MDMessage.set(
    '<p>Wenn Sie Ihr Passwort vergessen haben, oder noch gar keines besitzen, können Sie hier ein (neues) Passwort anfordern.</p><p>Bitte nennen Sie uns dazu Ihre E-Mail-Adresse, die Sie in der Kommunikation mit uns vom <i>Regionalen Strommarkt</i> verwenden.</p>'
  );
}
