import { API_URL } from "../constants/api_url";
import { isFetching } from '../stores/isFetching';

export async function postEmailConfirmation(token) {
  const tenantId = document.body.dataset.tenant;

  const HEADERS = {
    "Content-Type": "application/json",
    "Confirmation-Token": token,
  };

  isFetching.set(true);

  const res = await fetch(`${API_URL}/ova/mail-confirmation/${tenantId}`, {
    method: "POST",
    headers: HEADERS,
  });

  console.assert(res.ok);
  if (!res.ok) {
    isFetching.set(false);
    throw new Error();
  }
  
  isFetching.set(false);
  return;
}
