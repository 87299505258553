<script>
  import { onMount } from 'svelte';
  import { postSetPassword } from '../lib/iam/postSetPassword';
  import {
    MDType,
    MDTopic,
    MDHeadline,
    MDIcon,
    MDMessage,
    MDActions,
    MDHide,
    MDisVisible,
    MDShow,
    MDServerError,
  } from '../lib/modaldialogues';
  import { passwordResetState } from '../stores/passwordResetState';

  export let username = null;
  export let hash = null;

  let { tenant } = document.body.dataset;

  let passwordSetNewForm = {
    username: null,
    hash: null,
    tenantId: tenant || 'bayernwerk',
  };

  function onBlur(e) {
    e.target.reportValidity();
  }

  function onChange(e) {
    e.target.setCustomValidity('');
  }

  function onInvalid(e) {
    if (e.target.validity.patternMismatch) {
      e.target.setCustomValidity(
        'Diese Eingabe entspricht nicht den gültigen Passwort-Regeln.'
      );
    } else {
      e.target.setCustomValidity(e.target.dataset.invalid_message);
    }
  }

  function lower(e) {
    e.target.value = e.target.value.toLowerCase();
  }

  async function onSubmitNewPassword() {
    if (passwordSetNewForm.password !== passwordSetNewForm.password_confirm) {
      const pwdConfirm =
        document.forms['passwordSetNewForm'].elements['password_confirm'];
      pwdConfirm.setCustomValidity(pwdConfirm.dataset.invalid_message);
      // Thou shall not pass.
      pwdConfirm.reportValidity();
    } else {
      try {
        await postSetPassword(
          passwordSetNewForm.username,
          passwordSetNewForm.password,
          passwordSetNewForm.hash,
          passwordSetNewForm.tenantId
        );
        passwordResetState.set('PASSWORD_RESET_SUBMITTED');
        MDType.set('alert');
        MDTopic.set('Passwort ändern');
        MDHeadline.set('Das neue Passwort wurde gespeichert.');
        MDIcon.set('key');
        MDMessage.set(
          '<p>Wir haben das (neue) Passwort entgegen genommen und für die zukünftige Verwendung gespeichert. Das alte Passwort ist ab sofort nicht mehr gültig. Sie können diesen Vorgang jederzeit wiederholen.</p><p><b>Bitte melden Sie sich nun mit dem neuen Passwort an.</b></p>'
        );
        MDActions.set([
          {
            href: window.location.href,
            label: 'Fortfahren',
            cssClass: 'secondary proceed',
            onClick: () => {
              MDHide();
            },
          },
        ]);
        MDShow();
      } catch (e) {
        if (e.message === 'GENERAL_FIELD_CONTENT') {
          MDType.set('alert');
          MDTopic.set('Neues Passwort anfordern');
          MDHeadline.set('Eine der Angaben wurde nicht akzeptiert.');
          MDIcon.set('key');
          MDMessage.set(
            '<p>Eine der Angaben (E-Mail-Adresse, Sicherheitscode oder neues Passwort) entsprachen nicht den Erwartungen.</p><p><b>Bitte stellen Sie sicher, dass alle drei Angaben korrekt sind und das Passwort den Mindestanforderungen genügt.</b></p>'
          );
          MDActions.set([
            {
              href: window.location.href,
              label: 'Abbrechen',
              cssClass: 'secondary proceed',
              onClick: () => {
                MDHide();
              },
            },
          ]);
          MDShow();
        } else {
          MDServerError();
        }
      }
    }
  }

  onMount(() => {
    passwordSetNewForm.username = username;

    // A trailing ">" may occur at the end of the hash if a customer copies the link from the email incorrectly.
    // Since this issue has occured frequently, we are "auto-fixing" it for customers by removing trailing ">"'s.
    if (hash.endsWith('>')) hash = hash.slice(0, -1);

    passwordSetNewForm.hash = hash;
  });
</script>

<form
  name="passwordSetNewForm"
  id="passwordSetNewForm"
  class="fadein"
  on:submit|preventDefault={onSubmitNewPassword}
>
  <input
    type="hidden"
    name="tenantId"
    bind:value={passwordSetNewForm.tenantId}
  />
  <div class="formfield flyinglabel">
    <!-- svelte-ignore a11y-autofocus -->
    <input
      type="email"
      name="username"
      id="username"
      spellcheck="false"
      placeholder="E-Mail-Adresse"
      maxlength="255"
      autocapitalize="none"
      autocorrect="off"
      autocomplete="username"
      aria-labelledby="passwordSetNewForm.username.label"
      aria-describedby="passwordSetNewForm.username.formMessage"
      aria-required="true"
      bind:value={passwordSetNewForm.username}
      required
    />
    <label id="passwordSetNewForm.username.label" for="username"
      >E-Mail-Adresse</label
    >
    <div class="formmessage" id="passwordSetNewForm.username.formMessage" />
  </div>
  <div class="formfield flyinglabel">
    <textarea
      name="hash"
      id="hash"
      spellcheck="false"
      placeholder="Sicherheitscode"
      pattern="[a-z0-9]+"
      maxlength="255"
      autocapitalize="none"
      autocorrect="off"
      autocomplete="one-time-code"
      aria-labelledby="passwordSetNewForm.hash.label"
      aria-required="true"
      aria-invalid="false"
      bind:value={passwordSetNewForm.hash}
      required
      rows="3"
      style="font-family: monospace; font-size: 1.1em"
      on:input={lower}
    />
    <label id="passwordSetNewForm.username.label" for="hash"
      >Sicherheitscode</label
    >
    <p>
      Der Sicherheitscode ist Bestandteil des Links, den wir Ihnen per E-Mail
      zugeschickt haben und wird automatisch eingefügt.
    </p>
  </div>
  <div class="formfield flyinglabel">
    <!-- prettier-ignore -->
    <input
      type="password"
      name="password"
      spellcheck="false"
      placeholder="Neues Passwort"
      maxlength="255"
      autocomplete="new-password"
      aria-required="true"
      bind:value={passwordSetNewForm.password}
      on:invalid={onInvalid}
      on:blur={onBlur}
      on:change={onChange}
      passwordrules="minlength: 20; required: lower; required: upper; required: digit; required: [-];"
      pattern={"^(?=\\S*[a-zäöü])(?=\\S*[A-ZÄÖÜ])(?=\\S*\\d)(?=\\S*[#$^+=!*()\\{\\}\[\\]@%&§\"\'´`_,.\\-])\\S{8,}$"}
      data-invalid_message="Bitte tragen Sie hier das neue Wunsch-Passwort ein."
      required
    />
    <label for="password">Neues Passwort</label>
  </div>
  <div class="formfield flyinglabel">
    <!-- prettier-ignore -->
    <input
      type="password"
      name="password_confirm"
      spellcheck="false"
      placeholder="Neues Passwort wiederholen"
      autocomplete="new-password"
      bind:value={passwordSetNewForm.password_confirm}
      on:invalid={onInvalid}
      on:blur={onBlur}
      on:change={onChange}
      passwordrules="minlength: 20; required: lower; required: upper; required: digit; required: [-];"
      pattern={"^(?=\\S*[a-zäöü])(?=\\S*[A-ZÄÖÜ])(?=\\S*\\d)(?=\\S*[#$^+=!*()\\{\\}\[\\]@%&§\"\'´`_,.\\-])\\S{8,}$"}
      data-invalid_message="Bitte wiederholen Sie hier das neue Passwort exakt."
      required
    />
    <label for="password_confirm">Neues Passwort wiederholen</label>
  </div>
  <p>
    Bitte stellen Sie sicher, dass das Passwort nicht zu einfach ist (nicht
    geeignet sind Benutzername, einfache Zahlenfolge, oder Geburtsdatum),
    sondern diesen Mindestanforderungen genügt:
  </p>
  <ul class="info">
    <li>Mindestens 8 Zeichen lang, und …</li>
    <li>… enthält mindestens eine Zahl;</li>
    <li>… enthält mindestens einen Großbuchstaben;</li>
    <li>… enthält mindestens einen Kleinbuchstaben;</li>
    <li>… enthält mindestens ein Sonderzeichen.</li>
  </ul>
  <div class="formaction">
    <button id="passwordSetNewForm.submit" type="submit"
      >Passwort festlegen</button
    >
  </div>
</form>
