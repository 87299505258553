<script>
  import { forceImplausibleSaving } from './stores/contracts';
  import { fly, fade } from 'svelte/transition';
  import { MDAlert, MDHide, MDConfirm, MDReadings } from './lib/modaldialogues';
  import { fetchCustomerVerification } from './lib/fetchCustomerVerification';
  import { postReadingWithTan } from './lib/postReadingWithTan';
  import Spinner from './_Spinner.svelte';

  export let contractId = null;
  export let tan = null;

  let isVerified = false;
  let readingDate = null;
  let readingMeter;
  let allObis = [];

  //min-value for input Date
  let dateOffset = 24 * 60 * 60 * 1000 * 42; // 6 Weeks
  let today = new Date();
  let sixWeeksAgo = new Date(today.getTime() - dateOffset).toLocaleDateString(
    'en-CA'
  );

  function onBlur(e) {
    e.target.reportValidity();
  }

  function onChange(e) {
    e.target.setCustomValidity('');
  }

  function onInvalid(e) {
    e.target.setCustomValidity(e.target.dataset.invalid_message);
  }

  async function verifyCustomer() {
    try {
      const data = await fetchCustomerVerification(tan, contractId);

      readingMeter = data.attributes.deviceNumber;
      allObis = data.attributes.registers;
      isVerified = true;
    } catch (e) {
      console.error(e);
      MDAlert({
        topic: 'Verifizierung',
        headline: 'Verifizierung fehlgeschlagen.',
        message:
          'Es war uns leider nicht möglich, Sie zu verifizieren. Bitte überprüfen Sie Ihre Angaben, und versuchen Sie es erneut.',
        actions: [
          {
            label: 'Abbrechen',
            cssClass: 'primary proceed',
            onClick: () => MDHide(),
          },
        ],
      });
    }
  }

  function sanitizeDate(value) {
    const isGermanDateFormat = value.match(/^\d{1,2}\.\d{1,2}\.\d{4}$/);
    if (isGermanDateFormat) {
      let [day, month, year] = value.split('.');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    } else {
      return value;
    }
  }

  function getReadingValues() {
    let allReadingValues = [];
    let counter = 0;

    while (document.getElementById(`${'obis' + counter}`) != null) {
      const obis = document.getElementById(`${'obis' + counter}`).value;
      const readingValue = document.getElementById(
        `${'reading' + counter}`
      ).value;

      allReadingValues.push({
        obis: obis,
        value: parseInt(readingValue.trim(), 10),
      });
      counter++;
    }
    return allReadingValues;
  }

  async function submitReading() {
    const payload = {
      data: {
        id: document.body.dataset.region,
        type: 'readingV2',
        attributes: {
          readAt: sanitizeDate(readingDate),
          readingValues: getReadingValues(),
        },
      },
    };
    try {
      await postReadingWithTan(
        tan,
        contractId,
        $forceImplausibleSaving,
        payload
      );

      MDAlert({
        topic: 'Stromzählerstand erfassen.',
        headline: 'Der neue Zählerstand wurde gespeichert.',
        message: `Vielen Dank! Der neue Zählerstand für den Zähler mit der Nummer <tt>${readingMeter}</tt> wurde gespeichert.`,
        actions: [
          {
            label: 'Verstanden',
            cssClass: 'primary proceed',
            onClick: () => MDHide(),
          },
        ],
      });
    } catch (error) {
      console.error(error);
      switch (error.message) {
        case 'READING_VALUE_IMPLAUSIBLE':
          MDConfirm({
            topic: 'Stromzählerstand erfassen.',
            headline: 'Der Zählerstand erscheint nicht plausibel.',
            message:
              'Der von Ihnen eingebene Zählerstand weicht sehr stark von der Erwartung ab. Möchten Sie ihn noch einmal korrigieren oder dennoch speichern?',
            actions: [
              {
                label: 'Eingaben überprüfen',
                cssClass: 'primary proceed',
                onClick: () => {
                  MDReadings();
                },
              },
              {
                label: 'Zählerstand speichern',
                cssClass: 'secondary proceed',
                onClick: () => {
                  $forceImplausibleSaving = true;
                  submitReading();
                },
              },
            ],
          });
          break;
        case 'READING_NO_VALID_DEVICE':
          MDAlert({
            topic: 'Stromzählerstand erfassen.',
            headline: 'Der Zähler ist noch nicht aktiv.',
            message:
              'Der zu diesem Vertrag gewählte Zähler ist noch nicht aktiv und kann erst in Zukunft genutzt werden. Bitte versuchen Sie es erneut, sobald der Zähler aktiviert wurde.',
            actions: [
              {
                label: 'Abbrechen',
                cssClass: 'primary proceed',
                onClick: () => {
                  MDHide();
                },
              },
            ],
          });
          break;
        case 'READING_PREVIOUS_READING_WITH_HIGHER_VALUE':
          MDAlert({
            topic: 'Stromzählerstand erfassen.',
            headline: 'Der Zählerstand ist zu niedrig.',
            message:
              'Der von Ihnen erfasste Zählerstand ist niedriger als ein zuvor erfasster Wert. Bitte überprüfen Sie Ihre Angaben.',
            actions: [
              {
                label: 'Eingaben überprüfen',
                cssClass: 'primary proceed',
                onClick: () => {
                  MDReadings();
                },
              },
              {
                label: 'Zählerstand speichern',
                cssClass: 'secondary proceed',
                onClick: () => {
                  $forceImplausibleSaving = true;
                  submitReading();
                },
              },
            ],
          });
          break;
        case 'READING_PREVIOUS_READING_WITH_SMALLER_VALUE':
          MDAlert({
            topic: 'Stromzählerstand erfassen.',
            headline: 'Es existiert bereits ein niedrigerer Zählerstand.',
            message:
              'Für den Zählerstand, den Sie eingeben möchten, gibt es bereits einen Zählerstand mit einem niedrigeren Wert zu einem späteren Ablesedatum. Bitte überprüfen Sie Ihre Angaben.',
            actions: [
              {
                label: 'Eingaben überprüfen',
                cssClass: 'primary proceed',
                onClick: () => {
                  MDReadings();
                },
              },
              {
                label: 'Zählerstand speichern',
                cssClass: 'secondary proceed',
                onClick: () => {
                  MDHide();
                  $forceImplausibleSaving = true;
                  submitReading();
                },
              },
            ],
          });
          break;
        default:
          MDAlert({
            topic: 'Stromzählerstand erfassen.',
            headline: 'Die Eingabe wurde nicht akzeptiert.',
            message:
              'Der eingegebene Zählerstand konnte nicht gespeichert werden. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.',
            actions: [
              {
                label: 'Abbrechen',
                cssClass: 'primary proceed',
                onClick: () => {
                  MDReadings();
                },
              },
            ],
          });
          break;
      }
    }
  }

  if (contractId && tan) {
    verifyCustomer();
  }
</script>

<Spinner />

{#if !isVerified}
  <section out:fly={{ y: 200, duration: 500 }}>
    <form on:submit|preventDefault={verifyCustomer}>
      <p>
        Bitte verifizieren Sie sich zunächst mit Ihrer Vertragsnummer und der
        Ihnen zugewiesenen TAN:
      </p>
      <div class="fieldgroup">
        <div class="formfield required flyinglabel">
          <input name="customerNumber" type="text" bind:value={contractId} />
          <label for="customerNumber">Vertragsnummer:</label>
        </div>
        <div class="formfield required flyinglabel">
          <input name="tan" type="text" bind:value={tan} />
          <label for="tan">TAN:</label>
        </div>
      </div>
      <div class="formaction">
        <button type="submit">Fortfahren</button>
      </div>
    </form>
  </section>
{:else}
  <section
    class="tan-readings scrollable"
    transition:fade={{ duration: 500, delay: 500 }}
  >
    <form on:submit|preventDefault={submitReading} disabled={!isVerified}>
      <legend>Zählerstandseingabe</legend>
      <div class="fieldgroup" style="margin-top: 1em;">
        <div class="formfield flyinglabel">
          <input
            type="text"
            bind:value={readingMeter}
            id="reading_meter"
            name="reading_meter"
            aria-readonly="true"
            readonly
          />
          <label for={'reading_meter'}>Zählernummer:</label>
        </div>
        <div class="formfield required flyinglabel">
          <input
            name="readingDate"
            type="date"
            min={sixWeeksAgo}
            max={new Date().toLocaleDateString('en-CA')}
            data-invalid_message="Bitte geben Sie hier ein gültiges Datum an."
            aria-required="true"
            required
            on:blur={onBlur}
            on:change={onChange}
            on:invalid={onInvalid}
            bind:value={readingDate}
          /><label for="readingDate">Ablesedatum:</label>
        </div>
      </div>
      {#each allObis as obis, index}
        <div class="fieldgroup">
          <div class="formfield flyinglabel">
            <input
              type="text"
              id={'obis' + index}
              name={'obis' + index}
              value={obis.obis}
              aria-readonly="true"
              readonly
            />
            <label for={'obis' + index}>Zählwerk:</label>
          </div>
          <div class="formfield required flyinglabel">
            <input
              id={'reading' + index}
              name={'reading' + index}
              type="text"
              inputmode="numeric"
              aria-required="true"
              required
            />
            <label for={'reading' + index}>Zählerstand eingeben:</label>
          </div>
        </div>
      {/each}
      <div class="formaction">
        <button type="submit">Speichern</button>
      </div>

      <div class="info info--fullheight">
        <h4 style="margin-top: 0">Wie erfasse ich meinen Zählerstand?</h4>
        <p>
          Geben Sie das Datum an, an dem Sie ihren Zählerstand abgelesen haben
          und tragen Sie den Wert ohne Nachkommastellen in das zugehörige Feld
          ein. Das Feld mit ihrer Zählernummer ist bereits vorbelegt und dient
          nur zu Ihrer Information.
          <br /><br />
          Sollten Sie Fragen zu der Eingabe haben oder technische Störungen vorliegen,
          würden wir Sie bitten den Zählerstand per E-Mail, unter Angabe Ihrer Kundennummer,
          Vertragsnummer und dem Ablesedatum an uns weiterzuleiten.
          <br /><br />
          Vielen Dank
        </p>
      </div>
      <div class="info info--fullheight">
        <h4 style="margin-top: 0">Wie lese ich den Zählerstand ab?</h4>
        <p>
          Zwar gibt es viele verschiedene Bauarten, aber die Zählernummer und
          den Zählerstand finden Sie deutlich markiert auf Ihrem Stromzähler.
        </p>
      </div>
    </form>
  </section>
{/if}

<style lang="scss">
  .scrollable {
    max-height: 320px;
    overflow: scroll;
    padding: 0 1em;
    border: 1px solid silver;
    border-radius: 3px;
    background-color: hsl(0, 0%, 95%);
  }

  form:disabled {
    pointer-events: none;
    opacity: 0.3;
  }
</style>
