import { API_URL } from '../constants/api_url';
import { isFetching } from '../stores/isFetching';

export async function fetchCustomerVerification(tan, contractId) {
  const tenantId = document.body.dataset.tenant;

  const HEADERS = {
    'Content-Type': 'application/json',
    TAN: tan,
  };

  isFetching.set(true);

  const res = await fetch(
    `${API_URL}/public-csc/${tenantId}/${contractId}/device`,
    {
      method: 'GET',
      headers: HEADERS,
    }
  );

  console.assert(res.ok);
  if (!res.ok) {
    isFetching.set(false);
    throw new Error();
  }

  const result = await res.json();
  isFetching.set(false);
  return result.data;
}
