<script>
  import { get } from 'svelte/store';
  import { passwordResetState } from './stores/passwordResetState';
  import { postLogin, fetchRedirectOptions } from './lib/iam/postLogin';
  import { passwordReset } from './lib/iam/passwordReset';
  import {
    MDHide,
    MDAlert,
    MDAuthorizationFailed,
    MDUnconfirmedEmail,
  } from './lib/modaldialogues';
  import { redirectPath } from './stores/redirectPath';

  let { env, tenant, region } = document.body.dataset;
  region = region === 'bemo' ? 'schwandorf' : region;

  let loginForm = {
    username: '',
    password: '',
    tenantId: tenant || 'bayernwerk',
  };

  let customerRegions;

  let showPassword = false;
  let inputPassword;

  const env_subdomain = env === 'prod' ? 'www' : `dev-www`; // There is no trailing dot.

  const signUpPath = `https://${env_subdomain}.${tenant}.regionaler-strommarkt.de/${region}/registrierung`;

  function togglePasswordVisibility() {
    showPassword = !showPassword;
    inputPassword.type = showPassword ? 'text' : 'password';
  }

  function getNextPath() {
    return window.location.hostname !== 'localhost'
      ? `https://${env_subdomain}.${
          loginForm.tenantId
        }.regionaler-strommarkt.de/${get(redirectPath)}`
      : `http://${window.location.host}/`;
  }

  async function onSubmit() {
    const payload = {
      userId: loginForm.username,
      password: loginForm.password,
      tenantId: loginForm.tenantId,
    };
    try {
      await postLogin(payload);
      customerRegions = await fetchRedirectOptions();
      passwordResetState.set('SIGNED_IN');
      MDHide();

      const pathName = window.location.pathname.replace(/\//,'');
      const queryString = window.location.search;
      if (pathName.includes('ova')) {
        redirectPath.set(`${pathName}${queryString}`);
          // Leave this place …
        location.href = getNextPath();
      } else {
        if (customerRegions.length > 1) {
          let listOfRedirects = [];

          customerRegions.forEach((customerRegion) => {
            listOfRedirects.push({
              label: `${customerRegion}`,
              cssClass: 'primary proceed',
              onClick: () => {
                redirectPath.set(`${customerRegion}/konto/vertraege`);
                const nextPath = getNextPath()
                MDHide();
                location.href = nextPath;
              },
            });
          });
          
          MDAlert({
            topic: 'Login',
            headline: 'Verifizierung erfolgreich.',
            message:
              'Sie haben Verträge in mehreren Regionen. Bitte wählen Sie aus in welche Region sie weitergeleitet werden wollen.',
            actions: listOfRedirects,
          });
        } else {
          // reset redirectPath before we leave …
          redirectPath.set(`${customerRegions[0]}/konto/vertraege`);
          // Leave this place …
          location.href = getNextPath();
        }
      }
    } catch (e) {
      MDHide();
      switch (e.message) {
        case 'LOGIN_PENDING_MAILCONFIRMATION':
          MDUnconfirmedEmail();
          break;
        default:
          MDAuthorizationFailed();
          break;
      }
    }
  }
</script>

<section name="logincomponent">
  <form
    name="loginForm"
    id="loginForm"
    class="fadein"
    on:submit|preventDefault={onSubmit}
  >
    <input type="hidden" name="tenantId" bind:value={loginForm.tenantId} />
    <div class="formfield flyinglabel">
      <input
        type="email"
        name="username"
        id="loginForm.username"
        spellcheck="false"
        placeholder="E-Mail-Adresse"
        maxlength="255"
        autocapitalize="none"
        autocorrect="off"
        autocomplete="username"
        aria-labelledby="loginForm.username.label"
        aria-describedby="loginForm.username.formMessage"
        aria-required="true"
        aria-invalid="false"
        bind:value={loginForm.username}
        required
      />
      <label id="loginForm.username.label" for="username">E-Mail-Adresse</label>
      <div class="formmessage" id="loginForm.username.formMessage" />
    </div>
    <div class="formfield flyinglabel">
      <input
        type="password"
        name="password"
        id="loginForm.password"
        spellcheck="false"
        placeholder="Passwort"
        maxlength="255"
        autocomplete="current-password"
        aria-labelledby="loginForm.password.label"
        aria-describedby="loginForm.password.formMessage"
        aria-required="true"
        aria-invalid="false"
        bind:value={loginForm.password}
        bind:this={inputPassword}
        required
      />
      <svg
        id="hide-password"
        role="button"
        class="icon-eye {showPassword ? '' : 'hidden'}"
        aria-label="Passwort verbergen"
        viewBox="0 0 150 150"
        on:click={togglePasswordVisibility}
        on:keyup={() => {
          /* A11y */
        }}
        tabindex="0"
      >
        <g
          transform="matrix(10.714285714285714, 0, 0, 10.714285714285714, 0, 0)"
        >
          <path
            d="M12.29,5.4c.38.34.7.67.94.93a1,1,0,0,1,0,1.34C12.18,8.8,9.79,11,7,11H6.6"
          />
          <path
            d="M3.87,10.13A12.4,12.4,0,0,1,.77,7.67a1,1,0,0,1,0-1.34C1.82,5.2,4.21,3,7,3a6.56,6.56,0,0,1,3.13.87"
          />
          <line x1="12.5" y1="1.5" x2="1.5" y2="12.5" />
          <path d="M5.59,8.41A2,2,0,0,1,5,7,2,2,0,0,1,7,5a2,2,0,0,1,1.41.59" />
          <path d="M8.74,8A2,2,0,0,1,8,8.73" />
        </g>
      </svg>
      <svg
        id="show-password"
        role="button"
        class="icon-eye {showPassword ? 'hidden' : ''}"
        aria-label="Passwort anzeigen"
        viewBox="0 0 150 150"
        on:click={togglePasswordVisibility}
        on:keyup={() => {
          /* A11y */
        }}
        tabindex="0"
      >
        <g
          transform="matrix(10.714285714285714, 0, 0, 10.714285714285714, 0, 0)"
        >
          <path
            d="M13.23,6.33a1,1,0,0,1,0,1.34C12.18,8.8,9.79,11,7,11S1.82,8.8.77,7.67a1,1,0,0,1,0-1.34C1.82,5.2,4.21,3,7,3S12.18,5.2,13.23,6.33Z"
          />
          <circle cx="7" cy="7" r="2" />
        </g>
      </svg>
      <label id="loginForm.password.label" for="password">Passwort</label>
      <div class="formmessage" id="loginForm.password.formMessage" />
    </div>
    <div class="formaction" style="margin-top: 1em">
      <button id="loginForm.submit" type="submit">Anmelden</button>
      <a href={signUpPath} class="call-to-action unpreferrable">Registrieren</a>
      <a
        href="#/password-reset"
        class="call-to-action unpreferrable"
        on:click={(e) => {
          passwordResetState.set('SIGNED_OUT');
          passwordReset();
          e.preventDefault();
        }}>Passwort vergessen?</a
      >
    </div>
    <div class="footer" />
  </form>
</section>
