import { API_URL } from '../constants/api_url';
import { isFetching } from '../stores/isFetching';

export async function postReadingWithTan(
  tan,
  contractId,
  forceImplausibleSaving,
  payload
) {
  const tenantId = document.body.dataset.tenant;

  const HEADERS = {
    'Content-Type': 'application/vnd.api+json',
    TAN: tan,
  };

  isFetching.set(true);

  const res = await fetch(
    `${API_URL}/public-csc/${tenantId}/${contractId}/readings?forceImplausibleSaving=${forceImplausibleSaving}`,
    {
      method: 'POST',
      headers: HEADERS,
      body: JSON.stringify(payload),
    }
  );

  console.assert(res.ok);
  if (!res.ok) {
    const body = await res.json();
    const code = body.errors[0].code;
    isFetching.set(false);

    switch (code) {
      case 'READING_NO_VALID_CONTRACTSTATUS':
      case 'READING_NO_VALID_DEVICE':
      case 'READING_PERIOD_BILLED':
      case 'READING_PREVIOUS_READING_WITH_HIGHER_VALUE':
      case 'READING_PREVIOUS_READING_WITH_SMALLER_VALUE':
      case 'READING_VALUE_IMPLAUSIBLE':
        throw new Error(code);
      default:
        throw new Error('ERROR');
    }
  }

  isFetching.set(false);
  return;
}
