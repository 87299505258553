import { API_URL } from '../../constants/api_url';
import { isFetching } from '../../stores/isFetching';

export async function postSetPassword(
  email,
  password,
  hash,
  tenantId = 'bayernwerk'
) {
  const HEADERS = {
    'Content-Type': 'application/vnd.api+json',
  };

  isFetching.set(true);

  const res = await fetch(`${API_URL}/iam/public/setnewpassword`, {
    headers: HEADERS,
    method: 'POST',
    body: JSON.stringify({
      userId: email,
      password: password,
      hash: hash,
      tenantId: tenantId,
    }),
  });

  if (res.status !== 201) {
    const body = await res.json();
    isFetching.set(false);

    for (let error of body.errors) {
      if (error.code === 'GENERAL_FIELD_CONTENT') {
        throw new Error('GENERAL_FIELD_CONTENT');
      }
    }
  }

  isFetching.set(false);
  return true;
}
