<script>
  import { onMount } from 'svelte';
  import { checkLogin, logout } from './lib/iam/session';
  import {
    MDLogin,
    MDReadings,
    MDEmailConfirmation,
  } from './lib/modaldialogues';
  import ModalDialogues from './ModalDialogues.svelte';

  let { env, tenant, region } = document.body.dataset;
  const urlParams = new URLSearchParams(location.search);

  let contractId = null;
  let tan = null;
  let emailConfirmToken = null;

  onMount(() => {
    const loginLinks = document.querySelectorAll('.login-action');
    contractId = urlParams.get('contractId');
    tan = urlParams.get('tan');
    emailConfirmToken = urlParams.get('confirm');

    if (emailConfirmToken) {
      MDEmailConfirmation();
    } else if (contractId && tan) {
      console.debug(contractId, tan);
      MDReadings();
    } else {
      if (!checkLogin()) {
        if (loginLinks.length > 0) {
          for (let loginLink of loginLinks) {
            loginLink.children[0].innerHTML = 'Kundenkonto';
            loginLink.addEventListener('click', (e) => {
              MDLogin();
              e.preventDefault();
            });
          }
        }
      } else {
        console.debug('We are logged in and iterate loginLinks');
        [...loginLinks].forEach((a) => {
          a.classList.add('logged_in');
          a.children[0].innerHTML = 'Abmelden';
          a.addEventListener('click', (e) => {
            const env_subdomain = env === 'prod' ? 'www' : `dev-www`; // There is no trailing dot.
            const logout_href = `https://${env_subdomain}.${
              tenant || 'bayernwerk'
            }.regionaler-strommarkt.de/${region}`;
            console.debug(`Triggering logout to "${logout_href}".`);
            logout();
            window.location.href = logout_href;
            e.preventDefault();
          });
        });
      }
    }
  });
</script>

<!-- This should be included somewhere globally to make it work properly: -->
<ModalDialogues />
