<script>
  import { postRequestPassword } from '../lib/iam/postRequestPassword';
  import { passwordResetState } from '../stores/passwordResetState';
  import {
    MDType,
    MDTopic,
    MDHeadline,
    MDIcon,
    MDMessage,
    MDActions,
    MDHide,
    MDisVisible,
    MDShow,
    MDServerError,
  } from '../lib/modaldialogues';

  let { tenant, region } = document.body.dataset;
  let passwordResetForm = {
    username: '',
    tenantId: tenant || 'bayernwerk',
  };

  async function onPasswordReset() {
    try {
      //// Send password request for given username.
      await postRequestPassword(passwordResetForm.username, region);
      //// Then display next steps.
      passwordResetState.set('PASSWORD_RESET_REQUESTED');
      MDTopic.set('Passwort erfolgreich zurückgesetzt!');
      MDHeadline.set('Sie haben Post.');
      MDIcon.set('mailbox');
      MDMessage.set(
        '<p>Wenn wir unter der angegebenen E-Mail-Adresse ein Konto für Sie führen, erhalten Sie nun eine E-Mail von uns. Wahrscheinlich ist sie bereits eingetroffen. Schauen Sie gleich nach.</p><p class="info">Falls Sie auch in Ihrem Spam-Ordner nichts finden, können Sie den Vorgang wiederholen.</p>'
      );
    } catch (e) {
      passwordResetState.set('SIGNED_OUT');
      MDType.set('alert');
      MDTopic.set('Neues Passwort anfordern');
      MDHeadline.set('Erlaubte Anzahl der Anforderungen überschritten.');
      MDIcon.set('key');
      MDMessage.set(
        '<p>Aktuell wurde die Anzahl an möglichen Versuchen zur Änderung des Passworts dieses Benutzerkontos überschritten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.</p>'
      );
      MDActions.set([
        {
          href: window.location.href,
          label: 'Abbrechen',
          cssClass: 'secondary proceed',
          onClick: () => {
            MDHide();
          },
        },
      ]);
      MDShow();
    }
  }
</script>

<form
  name="passwordResetForm"
  id="passwordResetForm"
  class="fadein"
  on:submit|preventDefault={onPasswordReset}
>
  <input
    type="hidden"
    name="tenantId"
    bind:value={passwordResetForm.tenantId}
  />
  <div class="formfield flyinglabel">
    <input
      type="email"
      name="username"
      id="passwordResetForm.username"
      spellcheck="false"
      placeholder="E-Mail-Adresse"
      maxlength="255"
      autocapitalize="none"
      autocorrect="off"
      autocomplete="username"
      aria-labelledby="passwordResetForm.username.label"
      aria-describedby="passwordResetForm.username.formMessage"
      aria-required="true"
      aria-invalid="false"
      bind:value={passwordResetForm.username}
      required
    />
    <label id="passwordResetForm.username.label" for="username"
      >E-Mail-Adresse</label
    >
    <p style="margin-top: 1em;" class="info">
      Sie erhalten anschließend eine E-Mail mit weiteren Erklärungen.
    </p>
  </div>
  <div class="formaction">
    <button id="passwordResetForm.submit" type="submit"
      >Passwort zurücksetzen</button
    >
  </div>
  <div class="footer" />
</form>
