import { API_URL } from '../../constants/api_url';
import { isFetching } from '../../stores/isFetching';

export async function postRequestPassword(
  email,
  region,
  tenant = 'bayernwerk'
) {
  const HEADERS = {
    'Content-Type': 'application/vnd.api+json;charset=utf-8',
  };

  isFetching.set(true);

  const res = await fetch(`${API_URL}/iam/public/requestnewpassword`, {
    method: 'POST',
    body: JSON.stringify({
      userId: email,
      context: region,
      tenantId: tenant,
    }),
    headers: HEADERS,
  });

  console.assert(res.ok);
  // A 202 is expected to make us feel good.
  if (res.status !== 202) {
    isFetching.set(false);
    throw new Error(res.statusText);
  }

  // If successful we'll receive a HTTP 202 without body,
  // so no result is to be processed.
  isFetching.set(false);
  return true;
}
