import { writable, get } from 'svelte/store';
import { uuidv4 } from '../uuidv4';

// Time to live (TTL): Ten minutes converted to ms.
const TTL = 10 * 60 * 1000;

export const isLoggedIn = writable(false);

export function storeData(key, dataObject) {
  sessionStorage.setItem(key, JSON.stringify(dataObject));
}

export function readData(key) {
  return JSON.parse(sessionStorage.getItem(key));
}

export function login(session_data) {
  isLoggedIn.set(false);

  sessionStorage.setItem('rsm.session_id', uuidv4());
  sessionStorage.setItem('rsm.session_start', +new Date()); // See TTL for expiration
  sessionStorage.setItem('tenantId', session_data.tenantId);
  sessionStorage.setItem('userId', session_data.userId);
  sessionStorage.setItem('token', session_data.token);
  sessionStorage.setItem('issuedBy', session_data.issuedBy);
  sessionStorage.setItem('roles', JSON.stringify(session_data.roles));
  sessionStorage.setItem('issuedAt', session_data.issuedAt);
  sessionStorage.setItem('expires', session_data.expires);

  // Did we succeed? Did the sessionStorage work as expected?
  // Let's check:
  if (sessionStorage.getItem('token') !== null) {
    console.debug(
      '%c Login successful. ',
      'background-color: green; color: white;'
    );
    isLoggedIn.set(true);
  }
}

export function updateToken(new_token) {
  sessionStorage.setItem('token', new_token);
  console.debug('%c Token updated. ', 'background-color: green; color: white;');
}

export function logout() {
  sessionStorage.removeItem('rsm.session_id');
  sessionStorage.removeItem('rsm.session_start');
  console.debug('%c Logout. ', 'background-color: red; color: black;');
  isLoggedIn.set(false);
}

export function checkLogin() {
  const session_id = sessionStorage.getItem('rsm.session_id');
  const session_start = parseInt(
    sessionStorage.getItem('rsm.session_start'),
    10
  );

  if (session_id !== null && session_start !== null) {
    // console.debug(
    //   '%c [checkLogin] We have just found a session started on %s with the session_id "%s". ',
    //   'background-color: orange; color: black;',
    //   new Date(session_start),
    //   session_id
    // );
    // We've found a session and check against its time to live (TTL).
    if (session_start + TTL < new Date().getTime()) {
      isLoggedIn.set(false);
      console.debug(
        '%c [checkLogin] The session we have found is older then %sms and has been discarded. Logout is enforced.',
        'background-color: red; color: black;',
        TTL
      );
      logout();
    } else {
      isLoggedIn.set(true);
      // console.debug(
      //   '%c [checkLogin] The session we have found is still within the time limit of %sms.',
      //   'background-color: green; color: white;',
      //   TTL
      // );
    }
  } else {
    isLoggedIn.set(false);
    // console.debug(
    //   '%c [checkLogin] No session has been found. ',
    //   'background-color: yellow; color: black;'
    // );
  }
  return get(isLoggedIn);
}
