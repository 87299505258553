<script>
  import { onMount } from 'svelte';
  import { checkLogin } from './lib/iam/session';
  import { passwordReset } from './lib/iam/passwordReset';
  import { fade } from 'svelte/transition';
  import { passwordResetState } from './stores/passwordResetState';
  import {
    MDType,
    MDTopic,
    MDHeadline,
    MDIcon,
    MDMessage,
    MDActions,
    MDHide,
    MDisVisible,
  } from './lib/modaldialogues';

  import LoginComponent from './LoginComponent.svelte';
  import PasswordReset from './modalDialogueComponents/PasswordReset.svelte';
  import PasswordSetNew from './modalDialogueComponents/PasswordSetNew.svelte';
  import TanReadings from './TanReadings.svelte';
  import EmailConfirmation from './EmailConfirmation.svelte';

  const urlParams = new URLSearchParams(location.search);

  let contractId = null;
  let tan = null;

  contractId = urlParams.get('contractId');
  tan = urlParams.get('tan');

  let username = urlParams.get('userId') || null;
  let hash = urlParams.get('hash') || null;

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  function validateHash(hash) {
    const re = /[a-zA-Z0-9]+/;
    return re.test(hash);
  }

  function showPasswordSetNewDialogue() {
    console.debug(
      'ModalDialogues.svelte: showPasswordSetNewDialogue(). Receiving userId (username) and token (hash)?',
      username,
      hash
    );
    MDType.set('login');
    MDTopic.set('Passwort festlegen');
    MDHeadline.set('Legen Sie Ihr Passwort fest.');
    MDIcon.set('key');
    MDMessage.set(
      '<p>Bitte nennen Sie uns dazu Ihre E-Mail-Adresse und den Sicherheitscode, den wir Ihnen per E-Mail zugesendet haben. Beides wird automatisch über den Link übermittelt, den wir Ihnen per E-Mail zugesandt haben.</p>'
    );
  }

  onMount(() => {
    if (!checkLogin()) {
      passwordResetState.set('SIGNED_OUT');
    }

    if (
      validateEmail(urlParams.get('userId')) &&
      validateHash(urlParams.get('hash'))
    ) {
      // URL query params contain userId and hash. New state: PASSWORD_SET_NEW.
      passwordResetState.set('PASSWORD_SET_NEW');
      MDisVisible.set(true);
      showPasswordSetNewDialogue();
    }
  });
</script>

{#if $MDisVisible}
  <div
    class="modal-dialogue"
    role="dialog"
    data-type={$MDType}
    in:fade={{ duration: 150 }}
    out:fade={{ duration: 150 }}
  >
    <div class="modal-inner modal-{$MDType}">
      {#if $MDType === 'login' || $MDType === 'readings' || $MDType === 'emailConfirmation'}
        <button type="button" class="modal-close" on:click={MDHide}
          >x<span /></button
        >
      {/if}
      <div class="modal-topic modal-{$MDType}">
        <p>{$MDTopic}</p>
        <p class="state" style="display: none">{$passwordResetState}</p>
      </div>
      <div class="modal-headline modal-{$MDType}">
        <p>{$MDHeadline}</p>
      </div>
      <div class="modal-body modal-{$MDType}">
        <div class="modal-message">
          {#if $MDIcon}
            <div class="modal-icon {$MDIcon}" />
          {/if}
          <div class="modal-message_content">
            {@html $MDMessage}
          </div>
        </div>
        {#if $MDType === 'readings'}
          <TanReadings {contractId} {tan} />
        {/if}
        {#if $MDType === 'emailConfirmation'}
          <EmailConfirmation />
        {/if}
        {#if $MDType === 'login'}
          {#if $passwordResetState === 'SIGNED_OUT'}
            <LoginComponent />
          {/if}
          {#if $passwordResetState === 'PASSWORD_RESET'}
            <PasswordReset />
          {/if}
          {#if $passwordResetState === 'PASSWORD_RESET_REQUESTED'}
            <div class="formaction fadein">
              <a
                class="call-to-action"
                href="/"
                on:click={(e) => {
                  MDHide();
                  e.preventDefault();
                }}>Fortfahren</a
              >
              <a
                class="call-to-action unpreferrable"
                href="#/password-reset"
                on:click={(e) => {
                  passwordReset();
                  e.preventDefault();
                }}>Vorgang wiederholen</a
              >
            </div>
          {/if}
          {#if $passwordResetState === 'PASSWORD_SET_NEW'}
            <PasswordSetNew {username} {hash} />
          {/if}
        {/if}
      </div>
      {#if $MDType !== 'login'}
        <div class="modal-actions modal-{$MDType}">
          {#each $MDActions as action}
            <button
              type="button"
              class={action.cssClass}
              title={action.label}
              on:click|preventDefault={action.onClick}>{action.label}</button
            >
          {/each}
        </div>
      {/if}
    </div>
  </div>
{/if}
