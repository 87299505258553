import { API_URL } from '../../constants/api_url';
import { login } from './session';
import { isFetching } from '../../stores/isFetching';

export async function postLogin(credentials) {
  const HEADERS = {
    'Content-Type': 'application/vnd.api+json;charset=utf-8',
  };

  isFetching.set(true);

  const res = await fetch(`${API_URL}/iam/login`, {
    method: 'POST',
    body: JSON.stringify(credentials),
    headers: HEADERS,
  });

  console.assert(res.ok);
  if (!res.ok) {
    isFetching.set(false);
    const result = await res.json();
    console.error(res);
    throw new Error(result.errors[0].code);
  }

  const result = await res.json();
  login(result);

  isFetching.set(false);
  return result;
}

export async function fetchRedirectOptions() {
  const tenantId = sessionStorage.getItem('tenantId');
  const token = sessionStorage.getItem('token');
  
  if (token === null) {
    throw new Error('NO_SESSION');
  }

  const HEADERS = {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${token}`,
  };

  isFetching.set(true);

  const res = await fetch(`${API_URL}/iam/redirectOptions?tenantId=${tenantId}`, {
    method: 'GET',
    headers: HEADERS,
  });

  console.assert(res.ok);
  if (!res.ok) {
    isFetching.set(false);
    throw new Error(res.statusText);
  }

  const result = await res.json();

  isFetching.set(false);
  return result;
}

