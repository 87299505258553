<script>
  import { onMount } from 'svelte';
  import { postEmailConfirmation } from './lib/postEmailConfirmation';
  import Spinner from './_Spinner.svelte';

  const region = document.body.dataset.region;
  const redirectUrl =
    document.body.dataset.env === 'prod'
      ? `https://bayernwerk.regionaler-strommarkt.de/${region}/konto/vertraege`
      : `https://dev-www.bayernwerk.regionaler-strommarkt.de/${region}/konto/vertraege`;

  let isVerfied = false;

  onMount(async () => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('confirm');
    if (token) {
      try {
        await postEmailConfirmation(token);
        isVerfied = true;
      } catch (error) {
        console.error(error);
      }
    }
  });
</script>

<Spinner />

{#if isVerfied}
  <section class="segment">
    <div class="email-confirmation verified">
      <div class="feature-call">
        <h4>Ihre E-Mail-Adresse wurde bestätigt.</h4>
        <p>
          Danke, das Sie ihre E-Mail-Adresse bestätigt haben. Sie können sich
          jetzt im Kundenkonto anmelden.
        </p>
        <a href={redirectUrl} class="call-to-action" rel="button"
          >Zum Kundenkonto</a
        >
      </div>
    </div>
  </section>
{:else}
  <section class="segment">
    <div class="email-confirmation not-verified">
      <div class="feature-call">
        <h4>Ihre E-Mail-Adresse wurde nicht bestätigt.</h4>
        <p>
          Es tut uns leid, aber Ihre E-Mail-Adresse konnte nicht bestätigt
          werden.
        </p>
        <!-- Request new Email with token -->
      </div>
    </div>
  </section>
{/if}
